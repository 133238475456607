// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-sec{
  position: relative;
  padding: 75px 0 80px;
  @include media-query-min(lg){
    padding: 114px 0 120px;
  }
}
.main-consulting {
  background-color:$g_point-theme2-color;
  background-image:url(/views/res/imgs/main/bg_main_sec1_patten.png);
  background-repeat:no-repeat;
  background-position:left -890px bottom -40px;
  background-size:auto;
  @include media-query-min(lg){
    background-position:center bottom;
  }
  @keyframes pcWaving{
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1000px;
    }
  }
  @keyframes mWaving {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -500px;
    }
  }
  .wave{
    position: absolute;
    top: -113px;
    left: 0;
    width: 270%;
    height: 200px;
    animation: mWaving 3.3s cubic-bezier(0, 0, 0.59, 1) forwards;
    background: url(/views/res/imgs/main/img_main_wave.png) repeat-x left / 50%;
    @include media-query-min(lg){
      top: -129px;
      left: 0;
      width: 230%;
      animation: pcWaving 2s cubic-bezier(0, 0, 0.59, 1) forwards;
    }
  }
  .mainServiceSlide {
    visibility: hidden;
    .slick-list{
      overflow: unset;
      @include media-query-min(sm){
        padding-left: 15px;
        padding-right: 15px;
      }
      @include media-query-min(lg){
        padding-left: 0;
        padding-right: 0;
      }
    }
    .list-wrapper{
      @include media-query-min(lg){
        // &:nth-of-type(1) {
        //   .inner-wrap{
        //     animation: slideUpAnimation 1s .2s forwards cubic-bezier(0.12, 0.4, 0.41, 0.86);
        //   }
        // }
  
        // &:nth-of-type(2) {
        //   .inner-wrap{
        //     animation: slideUpAnimation 1s .4s forwards cubic-bezier(0.12, 0.4, 0.41, 0.86);
        //   }
        // }
  
        // &:nth-of-type(3) {
        //   .inner-wrap{
        //     animation: slideUpAnimation 1s .6s forwards cubic-bezier(0.12, 0.4, 0.41, 0.86);
        //   }
        // }
  
        // &:nth-of-type(4) {
        //   .inner-wrap{
        //     animation: slideUpAnimation 1s .8s forwards cubic-bezier(0.12, 0.4, 0.41, 0.86);
        //   }
        // }
      }
    }
    .inner-wrap {
      position: relative;
      padding-top: 114%;
      background: url(/views/res/imgs/main/icon_sec_service_bg.png) no-repeat center;
      background-size: cover;
      text-align: center;
      outline: none;
      @include media-query-max(544px){
        animation: none;
        opacity: 1;
      }
    }
    .main-service-con {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      outline:none;
      .title {
        font-size:24px;
        font-weight:700;
        letter-spacing: -0.06em;
        color: #000;
        &:before {
          content:'';
          display:block;
          width:60px;
          height:60px;
          margin:0 auto;
          background-image:url(/views/res/imgs/main/icon_sec_service_01.svg);
          background-repeat:no-repeat;
          background-position:center top;
          background-size: 50px;
        }
        @include media-query-min(lg){
          font-size: 23px;
        }
        &.easy{
          &:before{
            background-image:url(/views/res/imgs/main/icon_sec_service_02.svg);
          }
        }
        &.cloud{
          &:before{
            background-image:url(/views/res/imgs/main/icon_sec_service_03.svg);
          }
        }
        &.price {
          &:before {
            background-image: url(/views/res/imgs/main/icon_sec_service_04.svg);
          }
        }
      }
      .sub-title {
        margin:5px 0 10px;
        font-family: $font-lato;
        font-size:14px;
        color:#838383;
      }
      .desc {
        padding: 0 18px;
        line-height:1.25;
        font-size:16px;
        letter-spacing: -0.03em;
        color:#222;
        @include media-query-min(md){
          padding: 0 9px;
          min-height: 70px;
          font-size: 15px;
          line-height: 1.53;
        }
        @include media-query-min(xl){
          padding: 0 18px;
        }
      }
    }
    
  }
}
//IE 이슈 수정
html[class*='ie_'] .wave {
  animation: mWaving 3.3s 2s cubic-bezier(0, 0, 0.59, 1) forwards;
  @include media-query-min(lg){
    animation: pcWaving 2s 2s cubic-bezier(0, 0, 0.59, 1) forwards;
  }
}
.slick-initialized .slick-slide {
  outline:none;
}
.main-consulting {
  .slick-initialized .slick-slide {
    padding:0 7px;
  }
}
.mainServiceSlide { //slick option custom
  .slick-dots{
    margin-top: 20px;
    margin-bottom: 10px;
    text-align:center;
    li {
      display:inline-block;
      font-size:0;
      width:8px;
      height:8px;
      margin:0 3px;
      background-color:rgba(255,255,255,.5);
      border-radius: 50%;
      &.slick-active{
        background-color:#fff;
      }
    } 
  }
  .slick-arrow {
    position: absolute;
    bottom: -11px;
    width: 17px;
    height: 31px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 10;
    font-size: 0;
    &.slick-disabled{
      opacity: 0.4;
    }
    &.slick-prev {
      left:30px;
      background-image: url(/views/res/imgs/main/main_slick_prev.png);
    }
    &.slick-next{
      right:30px;
      background-image: url(/views/res/imgs/main/main_slick_next.png);
    }
  }
}
